import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Card, Button, Spinner } from "react-bootstrap";
import axios from "axios";

const ContentDetailPage = () => {
   const { contentId } = useParams();
   const location = useLocation();
   const { selectedBalise, locationCoords } = location.state || {};
   const [content, setContent] = useState(null);
   const [editor, setEditor] = useState(null);
   const [commune, setCommune] = useState("Commune inconnue");
   const [distance, setDistance] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      console.log("Selected balise:", selectedBalise);
      console.log("Location coordinates:", locationCoords);

      const fetchContent = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/contents/${contentId}`);
            const data = response.data;
            setContent(data);
            const user = await fetchUser(data.userId);
            setEditor(user);
            const fetchedCommune = await fetchCommune(data.balise.latitude, data.balise.longitude);
            setCommune(fetchedCommune);
            const calculatedDistance = calculateDistance(
               selectedBalise ? selectedBalise.latitude : locationCoords.latitude,
               selectedBalise ? selectedBalise.longitude : locationCoords.longitude,
               data.balise.latitude,
               data.balise.longitude
            );
            console.log("Calculated distance:", calculatedDistance);
            setDistance(calculatedDistance);
         } catch (error) {
            console.error("Error fetching content details:", error);
         } finally {
            setLoading(false);
         }
      };

      fetchContent();
   }, [contentId, selectedBalise, locationCoords]);

   const fetchUser = async (userId) => {
      try {
         const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`);
         const userData = response.data;
         return `${userData.firstName} ${userData.lastName}`;
      } catch (error) {
         console.error("Error fetching user details:", error);
         return "Utilisateur inconnu";
      }
   };

   const fetchCommune = async (latitude, longitude) => {
      try {
         const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
         return response.data.address.town || response.data.address.city || "Commune inconnue";
      } catch (error) {
         console.error("Error fetching commune:", error);
         return "Commune inconnue";
      }
   };

   const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a =
         Math.sin(dLat / 2) * Math.sin(dLat / 2) +
         Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in km
      return distance.toFixed(2);
   };

   const deg2rad = (deg) => {
      return deg * (Math.PI / 180);
   };

   return (
      <div className="container mt-3">
         {loading ? (
            <Spinner animation="border" variant="primary" />
         ) : content ? (
            <Card>
               <Card.Header as="h2">{content.title}</Card.Header>
               <Card.Img variant="top" src={content.imageUrl} alt={content.title} />
               <Card.Body>
                  <Card.Text>{content.body}</Card.Text>
                  <div>
                     <h5>Properties:</h5>
                     <ul>
                        {Object.entries(content.properties).map(([key, value]) => (
                           <li key={key}>{`${key}: ${value}`}</li>
                        ))}
                     </ul>
                  </div>
                  <Card.Text>
                     <strong>Éditeur:</strong> {editor} <br />
                     <strong>Commune:</strong> {commune} <br />
                     <strong>Distance:</strong> {distance} km
                  </Card.Text>
                  <Button variant="primary" onClick={() => alert("Messaging not implemented")}>
                     Envoyer un message
                  </Button>
               </Card.Body>
            </Card>
         ) : (
            <p>Loading content...</p>
         )}
      </div>
   );
};

export default ContentDetailPage;
