import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Button, Card, Spinner, Alert, DropdownButton, Dropdown } from "react-bootstrap";
import axios from "axios";
import RadiusSelectionModal from "./RadiusSelectionModal";

const SearchPage = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const [keywords, setKeywords] = useState(location.state?.keywords || "");
   const [locationCoords, setLocationCoords] = useState(null);
   const [searchRadius, setSearchRadius] = useState("5");
   const [modalShow, setModalShow] = useState(false);
   const [results, setResults] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [noResultsFound, setNoResultsFound] = useState(false);
   const [suggestedRadiusMessage, setSuggestedRadiusMessage] = useState(""); // State for suggested radius message
   const [errorMessage, setErrorMessage] = useState(""); // State for error messages

   // New state for balises and selected balise
   const [balises, setBalises] = useState([]);
   const [selectedBalise, setSelectedBalise] = useState(null);

   useEffect(() => {
      if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(
            (position) => {
               setLocationCoords({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
               });
            },
            (error) => {
               console.error("Erreur lors de l'obtention de la localisation", error);
               alert("Impossible de récupérer la localisation. Veuillez activer les services de localisation et réessayer.");
            }
         );
      } else {
         alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
      }

      // Fetch balises for the user
      fetchBalises();
   }, []);

   const fetchBalises = async () => {
      const userId = localStorage.getItem("userId");
      if (userId) {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/balises/user/${userId}`);
            setBalises(response.data);
         } catch (error) {
            console.error("Erreur lors de la récupération des balises :", error);
            setErrorMessage("Une erreur s'est produite lors de la récupération des balises.");
         }
      }
   };

   useEffect(() => {
      if (keywords && (locationCoords || selectedBalise)) {
         searchItems();
      }
   }, [keywords, locationCoords, searchRadius, selectedBalise]);

   const handleRadiusApply = (radius) => {
      setSearchRadius(radius);
      setModalShow(false);
      searchItems();
   };

   const searchItems = async () => {
      setIsLoading(true);
      const normalizedKeywords = keywords.toLowerCase(); // Convert keywords to lowercase

      const latitude = selectedBalise ? selectedBalise.latitude : locationCoords?.latitude;
      const longitude = selectedBalise ? selectedBalise.longitude : locationCoords?.longitude;

      const apiURL = `${process.env.REACT_APP_API_BASE_URL}/api/contents/search?keywords=${encodeURIComponent(
         normalizedKeywords // Use the normalized keywords here
      )}&latitude=${latitude}&longitude=${longitude}&searchRadius=${searchRadius}`;

      try {
         const response = await fetch(apiURL);
         const data = await response.json();

         if (data.length === 0) {
            const suggestedRadius = response.headers.get("X-Suggested-Radius");
            if (suggestedRadius) {
               setSuggestedRadiusMessage(`Aucun résultat trouvé. Essayez d'augmenter le rayon de recherche à ${suggestedRadius} km.`);
            } else {
               setSuggestedRadiusMessage("Aucun résultat trouvé dans le rayon spécifié. Essayez d'augmenter le rayon.");
            }
            setResults([]);
            setNoResultsFound(true);
         } else {
            setResults(data);
            setSuggestedRadiusMessage("");
            setNoResultsFound(false);
         }
      } catch (error) {
         console.error("Erreur lors de la récupération des données :", error);
         setErrorMessage("Une erreur s'est produite lors de la recherche.");
         setNoResultsFound(true);
      } finally {
         setIsLoading(false);
      }
   };

   return (
      <div className="container">
         <div className="search-page-container mt-3">
            <InputGroup className="mb-4">
               <FormControl
                  placeholder="Tapez des mots-clés..."
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && keywords && (locationCoords || selectedBalise) && searchItems()}
               />
               <Button
                  variant="outline-primary"
                  onClick={() => keywords && (locationCoords || selectedBalise) && searchItems()}
               >
                  Rechercher
               </Button>
               <Button
                  variant="outline-secondary"
                  onClick={() => setModalShow(true)}
                  className="ml-2"
               >
                  Définir le rayon
               </Button>

               {/* Dropdown for selecting a balise */}
               <DropdownButton
                  id="dropdown-balise"
                  title={selectedBalise ? selectedBalise.name : "Sélectionner une balise"}
                  className="ml-2"
                  variant="outline-secondary"
               >
                  {balises.map((balise) => (
                     <Dropdown.Item key={balise.id} onClick={() => setSelectedBalise(balise)}>
                        {balise.name}
                     </Dropdown.Item>
                  ))}
               </DropdownButton>
            </InputGroup>

            {isLoading && <Spinner animation="border" variant="primary" />}
            {noResultsFound && (
               <div className="d-flex justify-content-center">
                  <Alert variant="warning" style={{ maxWidth: "600px", textAlign: "center" }}>
                     {suggestedRadiusMessage || "Aucun résultat trouvé. Veuillez ajuster vos paramètres de recherche."}
                  </Alert>
               </div>
            )}
            <div className="d-flex flex-wrap justify-content-center">
               {results.map((item) => (
                  <Card
                     key={item.id}
                     style={{ width: "18rem", margin: "10px" }}
                     onClick={() => navigate(`/content/${item.id}`)}
                  >
                     <Card.Img
                        variant="top"
                        src={item.imageUrl}
                        alt={item.title}
                        style={{ height: "200px", objectFit: "cover" }}
                     />
                     <Card.Body>
                        <Card.Title>{item.title}</Card.Title>
                        <Card.Text>
                           {item.body.length > 100 ? item.body.substring(0, 100) + "..." : item.body}
                        </Card.Text>
                        <Button variant="primary">Voir les détails</Button>
                     </Card.Body>
                  </Card>
               ))}
            </div>
            <RadiusSelectionModal show={modalShow} handleClose={() => setModalShow(false)} handleApply={handleRadiusApply} />
         </div>
      </div>
   );
};

export default SearchPage;
