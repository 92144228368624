import React from 'react';
import Footer from './Footer';
import MainContent from './MainContent';
import HeroSection from './HeroSection'; // Import the HeroSection component
import MyContents from './MyContents';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook

const HomePage = () => {
  const { user } = useAuth(); // Get user from AuthContext

  return (
    <div>
      <HeroSection />
      <MainContent />
      <div style={{ margin: '20px 0', borderBottom: '1px solid #ccc' }}></div> 
      {user && <MyContents />} 
      <Footer />
    </div>
  );
};

export default HomePage;
