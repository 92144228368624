import React from 'react';
import { Link } from 'react-router-dom';

const VerificationFailed = () => {
    return (
        <div className="verification-container">
            <h1>Échec de la Vérification</h1>
            <p>La vérification de votre adresse email a échoué. Veuillez réessayer.</p>
            <Link to="/signup">Cliquez ici pour vous inscrire à nouveau</Link>
        </div>
    );
};

export default VerificationFailed;
