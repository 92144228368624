import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { useAuth } from '../context/AuthContext';

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">Mon espace</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {user && user.role === 'admin' && (
              <>
                <Nav.Link as={Link} to="/admin/users">Liste des utilisateurs</Nav.Link>
                <Nav.Link as={Link} to="/admin/add-category">Ajouter une catégorie</Nav.Link>
              </>
            )}
          </Nav>
          <Nav>
            {user ? (
              <>
                <Button 
                  variant="primary" 
                  onClick={() => navigate('/add-content')} 
                  className="me-2"
                >
                  Ajouter du contenu
                </Button>
                {user.role === 'admin' && (
                  <Button 
                    variant="secondary" 
                    onClick={() => navigate('/admin/add-user')} 
                    className="me-2"
                  >
                    Ajouter un utilisateur
                  </Button>
                )}
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {user.firstName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Nav.Link as={Link} to="/login">Connexion</Nav.Link>
                <Nav.Link as={Link} to="/signup">S'inscrire</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
