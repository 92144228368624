import React, { useState } from "react";
import axios from "axios";

function AdminAddUser() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: "user", // Default role is 'user'
        address: "",
        enabled: true // Ensure the user is enabled by default
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/admin/add-user`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                }
            });
            console.log(response.data);
            alert("Utilisateur ajouté avec succès");
            // Reset form
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                role: "user",
                address: "",
                enabled: true
            });
        } catch (error) {
            console.error("Échec de l'ajout de l'utilisateur: ", error.response ? error.response.data : error.message);
            alert("Échec de l'ajout de l'utilisateur");
        }
    };

    return (
        <div className="container mt-5">
            <h2>Ajouter un utilisateur</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">Prénom</label>
                    <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">Nom</label>
                    <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Mot de passe</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="address" className="form-label">Adresse</label>
                    <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="role" className="form-label">Rôle</label>
                    <select
                        className="form-control"
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                    >
                        <option value="user">Utilisateur</option>
                        <option value="admin">Administrateur</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="enabled" className="form-label">Actif</label>
                    <select
                        className="form-control"
                        id="enabled"
                        name="enabled"
                        value={formData.enabled}
                        onChange={handleChange}
                    >
                        <option value={true}>Oui</option>
                        <option value={false}>Non</option>
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Soumettre</button>
            </form>
        </div>
    );
}

export default AdminAddUser;
