import React, { useState, useEffect } from 'react';
import { InputGroup, FormControl, Button, Card, Spinner, Alert, DropdownButton, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import MapComponent from './MapComponent';
import './MainContent.css';

const MainContent = () => {
    const [keywords, setKeywords] = useState('');
    const [locationCoords, setLocationCoords] = useState(null);
    const [searchRadius, setSearchRadius] = useState("5");
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [noResultsFound, setNoResultsFound] = useState(false);
    const [suggestedRadiusMessage, setSuggestedRadiusMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [balises, setBalises] = useState([]);
    const [selectedBalise, setSelectedBalise] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocationCoords({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Erreur lors de l'obtention de la localisation", error);
                    alert("Impossible de récupérer la localisation. Veuillez activer les services de localisation et réessayer.");
                }
            );
        } else {
            alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
        }

        if (user) {
            fetchBalises();
        }
    }, [user]);

    useEffect(() => {
        if (keywords && (locationCoords || selectedBalise)) {
            searchItems();
        }
    }, [locationCoords, keywords]);

    const fetchBalises = async () => {
        const userId = localStorage.getItem("userId");
        if (userId) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/balises/user/${userId}`);
                setBalises(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des balises :", error);
                setErrorMessage("Une erreur s'est produite lors de la récupération des balises.");
            }
        }
    };

    const searchItems = async () => {
        if (!locationCoords && !selectedBalise) {
            alert("Impossible de récupérer la localisation. Veuillez activer les services de localisation et réessayer.");
            return;
        }

        setIsLoading(true);
        const normalizedKeywords = keywords.toLowerCase();

        const latitude = selectedBalise ? selectedBalise.latitude : locationCoords.latitude;
        const longitude = selectedBalise ? selectedBalise.longitude : locationCoords.longitude;

        const apiURL = `${process.env.REACT_APP_API_BASE_URL}/api/contents/search?keywords=${encodeURIComponent(
            normalizedKeywords
        )}&latitude=${latitude}&longitude=${longitude}&searchRadius=${searchRadius}`;

        try {
            const response = await axios.get(apiURL);
            const data = response.data;

            if (data.length === 0) {
                const suggestedRadius = await performInternalSearch(normalizedKeywords, latitude, longitude);
                if (suggestedRadius) {
                    setSuggestedRadiusMessage(`Aucun résultat trouvé. Essayez d'augmenter le rayon de recherche à ${suggestedRadius} km.`);
                } else {
                    setSuggestedRadiusMessage("Aucun résultat trouvé dans le rayon spécifié. Essayez d'augmenter le rayon.");
                }
                setResults([]);
                setNoResultsFound(true);
            } else {
                const enrichedResults = await Promise.all(data.map(async (item) => {
                    const user = await fetchUser(item.userId);
                    const commune = await fetchCommune(item.balise.latitude, item.balise.longitude);
                    const distance = calculateDistance(latitude, longitude, item.balise.latitude, item.balise.longitude);
                    return { ...item, user, commune, distance };
                }));

                setResults(enrichedResults.sort((a, b) => a.distance - b.distance));
                setSuggestedRadiusMessage("");
                setNoResultsFound(false);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
            setErrorMessage("Une erreur s'est produite lors de la recherche.");
            setNoResultsFound(true);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUser = async (userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`);
            const userData = response.data;
            return { name: `${userData.firstName} ${userData.lastName}` };
        } catch (error) {
            console.error("Erreur lors de la récupération de l'utilisateur:", error);
            return { name: "Utilisateur inconnu" };
        }
    };

    const fetchCommune = async (latitude, longitude) => {
        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
            return response.data.address.town || response.data.address.city || "Commune inconnue";
        } catch (error) {
            console.error("Erreur lors de la récupération de la commune:", error);
            return "Commune inconnue";
        }
    };

    const performInternalSearch = async (keywords, latitude, longitude) => {
        const apiURL = `${process.env.REACT_APP_API_BASE_URL}/api/contents/search?keywords=${encodeURIComponent(
            keywords
        )}&latitude=${latitude}&longitude=${longitude}&searchRadius=99999`;

        try {
            const response = await axios.get(apiURL);
            const data = response.data;
            if (data.length > 0) {
                const distances = data.map(item => calculateDistance(latitude, longitude, item.balise.latitude, item.balise.longitude));
                const minDistance = Math.min(...distances);
                return Math.ceil(minDistance);
            }
        } catch (error) {
            console.error("Erreur lors de la recherche interne:", error);
        }
        return null;
    };

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371;
        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const deg2rad = (deg) => {
        return deg * (Math.PI / 180);
    };

    const handleMarkerClick = (contentId) => {
        navigate(`/content/${contentId}`, { state: { selectedBalise, locationCoords } });
    };

    return (
        <div className="container mt-5">
            <div className="main-content text-center">
                <p className="mb-4">Commencez à rechercher le contenu dont vous avez besoin ou découvrez ce qui se trouve à proximité :</p>
                <InputGroup className="mb-3 mx-auto search-group">
                    <FormControl
                        placeholder="Tapez des mots-clés..."
                        value={keywords}
                        onChange={e => setKeywords(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" && keywords && (locationCoords || selectedBalise) && searchItems()}
                        className="mr-2"
                    />
                    {user ? (
                        <DropdownButton
                            id="dropdown-balise"
                            title={selectedBalise ? selectedBalise.name : "Sélectionner une balise"}
                            variant="outline-secondary"
                            className="mr-2"
                        >
                            {balises.map((balise) => (
                                <Dropdown.Item key={balise.id} onClick={() => setSelectedBalise(balise)}>
                                    {balise.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    ) : (
                        <DropdownButton
                            id="dropdown-balise"
                            title="Sélectionner une balise"
                            variant="outline-secondary"
                            className="mr-2"
                        >
                            <Dropdown.ItemText>
                                Pour ajouter ou sélectionner une balise, veuillez vous connecter.
                            </Dropdown.ItemText>
                            <Dropdown.Item onClick={() => navigate('/login')}>
                                Connexion
                            </Dropdown.Item>
                        </DropdownButton>
                    )}
                    <FormControl
                        type="number"
                        placeholder="Rayon de recherche en km"
                        value={searchRadius}
                        onChange={e => setSearchRadius(e.target.value)}
                        className="mr-2"
                    />
                    <Button variant="outline-secondary" onClick={searchItems}>
                        Rechercher
                    </Button>
                </InputGroup>
                {selectedBalise && (
                    <div className="map-container mb-3">
                        <MapComponent 
                            lat={selectedBalise.latitude} 
                            lng={selectedBalise.longitude} 
                            radius={searchRadius} 
                            results={results} 
                            onMarkerClick={handleMarkerClick} 
                        />
                    </div>
                )}
                {isLoading && <Spinner animation="border" variant="primary" />}
                {noResultsFound && (
                    <div className="d-flex justify-content-center">
                        <Alert variant="warning" style={{ maxWidth: "600px", textAlign: "center" }}>
                            {suggestedRadiusMessage || "Aucun résultat trouvé. Veuillez ajuster vos paramètres de recherche."}
                        </Alert>
                    </div>
                )}
                {results.length > 0 && <h3>Voici les résultats de votre recherche :</h3>}
                <div className="d-flex flex-wrap justify-content-center">
                    {results.map((item) => (
                        <Card
                            key={item.id}
                            style={{ width: "18rem", margin: "10px" }}
                            onClick={() => {
                                console.log("Navigating to content detail page with state:", { selectedBalise, locationCoords });
                                navigate(`/content/${item.id}`, { state: { selectedBalise, locationCoords } });
                            }}
                        >
                            <Card.Img
                                variant="top"
                                src={item.imageUrl}
                                alt={item.title}
                                style={{ height: "200px", objectFit: "cover" }}
                            />
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <Card.Text>
                                    {item.body.length > 100 ? item.body.substring(0, 100) + "..." : item.body}
                                </Card.Text>
                                <Card.Text>
                                    <strong>Éditeur:</strong> {item.user.name} <br />
                                    <strong>Commune:</strong> {item.commune} <br />
                                    <strong>Distance:</strong> {item.distance.toFixed(2)} km
                                </Card.Text>
                                <Button variant="primary">Voir les détails</Button>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MainContent;
