// src/components/AddCategory.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Card, Alert } from 'react-bootstrap';

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/categories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`, // Assuming JWT is used
        },
        body: JSON.stringify({ name: categoryName }),
      });

      if (response.ok) {
        setSuccess('Category added successfully.');
        setCategoryName('');
        setError('');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to add category.');
        setSuccess('');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
      setSuccess('');
    }
  };

  return (
    <Card className="mt-3">
      <Card.Body>
        <h2>Add Category</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="categoryName">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter category name"
              value={categoryName}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Add Category
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddCategory;
