// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('userToken');
    const firstName = localStorage.getItem('userFirstName');
    const role = localStorage.getItem('userRole');

    if (userId && token && firstName && role) {
      setUser({
        token: token,
        userId: userId,
        firstName: firstName,
        role: role
      });
    }
  }, []);

  const login = (userData) => {
    console.log("Setting user data:", userData); // Log user data
    localStorage.setItem('userId', userData.id);
    localStorage.setItem('userToken', userData.token);
    localStorage.setItem('userFirstName', userData.firstName);
    localStorage.setItem('userRole', userData.role); // Store role in localStorage
    setUser({
      token: userData.token,
      userId: userData.id,
      firstName: userData.firstName,
      role: userData.role
    });
  };

  const logout = () => {
    console.log("Logging out user"); // Log logout action
    localStorage.removeItem('userId');
    localStorage.removeItem('userToken');
    localStorage.removeItem('userFirstName');
    localStorage.removeItem('userRole'); // Remove role from localStorage
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
