import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Form, Card, Container, Alert } from "react-bootstrap";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useNavigate } from "react-router-dom";

const AddContentForm = () => {
   const [formData, setFormData] = useState({
      categoryId: "",
      baliseId: "",
      title: "",
      body: "",
      properties: {},
      imageUrl: "",
      keywords: "",
      searchRadius: "",
   });
   const [categories, setCategories] = useState([]);
   const [balises, setBalises] = useState([]);
   const [selectedBaliseLocation, setSelectedBaliseLocation] = useState(null);
   const [imageFile, setImageFile] = useState(null);
   const [formErrors, setFormErrors] = useState({});
   const mapRef = useRef(null);
   const navigate = useNavigate();

   const customIcon = new L.Icon({
      iconUrl: require("../marker-icon.png"),
      iconRetinaUrl: require("../marker-icon-2x.png"),
      shadowUrl: require("../marker-shadow.png"),
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
   });

   useEffect(() => {
      fetchInitialData();
   }, []);

   const fetchInitialData = async () => {
      const userId = localStorage.getItem("userId");
      if (userId) {
         fetchCategories();
         fetchBalises(userId);
      }
   };

   const fetchCategories = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/categories`);
      setCategories(response.data);
   };

   const fetchBalises = async (userId) => {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/balises/user/${userId}`);
      setBalises(response.data);
   };

   const handleImageChange = (e) => {
      setImageFile(e.target.files[0]);
   };

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      if (name === "baliseId") {
         const selectedBalise = balises.find((b) => b.id.toString() === value);
         if (selectedBalise) {
            setSelectedBaliseLocation({
               lat: selectedBalise.latitude,
               lng: selectedBalise.longitude,
            });
         }
      }
   };

   const renderMap = () => {
      return selectedBaliseLocation ? (
         <MapContainer
            ref={mapRef}
            center={[selectedBaliseLocation.lat, selectedBaliseLocation.lng]}
            zoom={13}
            style={{ height: "300px", width: "100%" }}
            whenCreated={(map) => map.invalidateSize()}
         >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
               position={[selectedBaliseLocation.lat, selectedBaliseLocation.lng]}
               icon={customIcon}
            ></Marker>
         </MapContainer>
      ) : null;
   };

   const validateForm = () => {
      const errors = {};
      if (!formData.categoryId) errors.categoryId = "Catégorie est obligatoire.";
      if (!formData.baliseId) errors.baliseId = "Balise est obligatoire.";
      if (!formData.title.trim()) errors.title = "Titre est obligatoire.";
      if (!formData.body.trim()) errors.body = "Description est obligatoire.";
      if (!imageFile) errors.imageFile = "Image est obligatoire.";
      if (!formData.keywords.trim()) errors.keywords = "Mots-clés sont obligatoires.";
      if (!formData.searchRadius || isNaN(formData.searchRadius) || formData.searchRadius <= 0) errors.searchRadius = "Rayon de recherche valide est obligatoire.";

      setFormErrors(errors);
      return Object.keys(errors).length === 0;
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      if (!validateForm()) return;

      const userId = localStorage.getItem("userId");
      if (!userId) {
         alert("L'ID utilisateur est manquant. Veuillez vous reconnecter.");
         return;
      }

      const contentData = {
         categoryId: formData.categoryId,
         baliseId: formData.baliseId,
         title: formData.title,
         body: formData.body,
         keywords: formData.keywords.split(",").map(kw => kw.trim()),
         searchRadius: parseFloat(formData.searchRadius),
         userId: userId,
      };
      const combinedFormData = new FormData();
      combinedFormData.append("content", new Blob([JSON.stringify(contentData)], { type: "application/json" }));
      combinedFormData.append("file", imageFile);

      try {
         const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/contents`, combinedFormData, {
            headers: {
               "Content-Type": "multipart/form-data",
            },
         });
         if (response.status === 200) {
            alert("Contenu ajouté avec succès !");
            setFormData({
               categoryId: "",
               baliseId: "",
               title: "",
               body: "",
               properties: {},
               imageUrl: "",
               keywords: "",
               searchRadius: "",
            }); // Reset form
            setImageFile(null); // Reset image file
            navigate("/"); // Redirect to home page
         } else {
            throw new Error("Échec de la soumission du contenu");
         }
      } catch (error) {
         console.error("Erreur de soumission du contenu:", error);
         alert("Échec de la soumission du contenu: " + error.message);
      }
   };

   return (
      <Container className="mt-3">
         <Card>
            <Card.Header>Ajouter du nouveau contenu</Card.Header>
            <Card.Body>
               <Form onSubmit={handleSubmit}>
                  {Object.keys(formErrors).map((key) => (
                     <Alert key={key} variant="danger">
                        {formErrors[key]}
                     </Alert>
                  ))}
                  <Form.Group controlId="formCategory">
                     <Form.Label>Catégorie</Form.Label>
                     <Form.Control
                        as="select"
                        name="categoryId"
                        value={formData.categoryId}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.categoryId}
                     >
                        <option value="">Sélectionnez une catégorie</option>
                        {categories.map((category) => (
                           <option key={category.id} value={category.id}>
                              {category.name}
                           </option>
                        ))}
                     </Form.Control>
                     <Form.Control.Feedback type="invalid">
                        {formErrors.categoryId}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBalise">
                     <Form.Label>Balise</Form.Label>
                     <Form.Control
                        as="select"
                        name="baliseId"
                        value={formData.baliseId}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.baliseId}
                     >
                        <option value="">Sélectionnez une balise</option>
                        {balises.map((balise) => (
                           <option key={balise.id} value={balise.id}>
                              {balise.name}
                           </option>
                        ))}
                     </Form.Control>
                     <Form.Control.Feedback type="invalid">
                        {formErrors.baliseId}
                     </Form.Control.Feedback>
                  </Form.Group>
                  {renderMap()}
                  <Form.Group controlId="formTitle">
                     <Form.Label>Titre</Form.Label>
                     <Form.Control
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.title}
                        placeholder="Entrez le titre"
                     />
                     <Form.Control.Feedback type="invalid">
                        {formErrors.title}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBody">
                     <Form.Label>Description</Form.Label>
                     <Form.Control
                        as="textarea"
                        name="body"
                        rows={3}
                        value={formData.body}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.body}
                        placeholder="Entrez la description"
                     />
                     <Form.Control.Feedback type="invalid">
                        {formErrors.body}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formKeywords">
                     <Form.Label>Mots-clés</Form.Label>
                     <Form.Control
                        type="text"
                        name="keywords"
                        value={formData.keywords}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.keywords}
                        placeholder="Entrez les mots-clés, séparés par des virgules"
                     />
                     <Form.Control.Feedback type="invalid">
                        {formErrors.keywords}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formSearchRadius">
                     <Form.Label>Rayon de recherche (en km)</Form.Label>
                     <Form.Control
                        type="number"
                        name="searchRadius"
                        value={formData.searchRadius}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.searchRadius}
                        placeholder="Entrez le rayon de recherche en kilomètres"
                     />
                     <Form.Control.Feedback type="invalid">
                        {formErrors.searchRadius}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formImage">
                     <Form.Label>Image</Form.Label>
                     <Form.Control 
                        type="file" 
                        onChange={handleImageChange} 
                        isInvalid={!!formErrors.imageFile}
                     />
                     <Form.Control.Feedback type="invalid">
                        {formErrors.imageFile}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                     Soumettre
                  </Button>
               </Form>
            </Card.Body>
         </Card>
      </Container>
   );
};

export default AddContentForm;
