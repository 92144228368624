import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, ListGroup, Row, Col, Container } from 'react-bootstrap';

const UserProfile = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('userToken');

      if (id && token) {
        try {
          const userResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/${id}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
          });

          if (!userResponse.ok) {
            throw new Error(`Failed to fetch user data: HTTP status code ${userResponse.status}`);
          }

          const userData = await userResponse.json();
          setUserData(userData);

          const postsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/contents/user/${id}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
          });

          if (!postsResponse.ok) {
            throw new Error(`Failed to fetch user posts: HTTP status code ${postsResponse.status}`);
          }

          const postsData = await postsResponse.json();
          setUserPosts(postsData);
        } catch (error) {
          console.error("Failed to fetch user data or posts:", error);
          setError(error.message);
        }
      } else {
        setError("User ID or token is missing.");
      }
    };

    fetchUserData();
  }, [id]);

  return (
    <Container className="mt-3">
      {error && <p className="text-danger">{error}</p>}
      <Card className="mb-4">
        <Card.Header as="h5">Profil Utilisateur</Card.Header>
        <ListGroup variant="flush">
          {userData ? (
            <>
              <ListGroup.Item><strong>Nom:</strong> {userData.firstName} {userData.lastName}</ListGroup.Item>
              <ListGroup.Item><strong>Email:</strong> {userData.email}</ListGroup.Item>
              <ListGroup.Item><strong>Adresse:</strong> {userData.address}</ListGroup.Item>
              <ListGroup.Item><strong>Latitude:</strong> {userData.latitude}</ListGroup.Item>
              <ListGroup.Item><strong>Longitude:</strong> {userData.longitude}</ListGroup.Item>
            </>
          ) : (
            <ListGroup.Item>Chargement...</ListGroup.Item>
          )}
        </ListGroup>
      </Card>

      <h3 className="mb-4">Contenus de l'Utilisateur</h3>
      <Row>
        {userPosts.length > 0 ? (
          userPosts.map(post => (
            <Col md={4} key={post.id} className="mb-4">
              <Card>
                <Card.Img className="content-image" variant="top" src={post.imageUrl} alt={post.title} />
                <Card.Body>
                  <Card.Title>{post.title}</Card.Title>
                  <Card.Text>{post.body}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <Card>
              <Card.Body>
                <Card.Text>Aucun contenu trouvé.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default UserProfile;
