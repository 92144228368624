import React from 'react';
import { Link } from 'react-router-dom';

const VerificationSuccess = () => {
    return (
        <div className="verification-container">
            <h1>Vérification Réussie</h1>
            <p>Votre adresse email a été vérifiée avec succès.</p>
            <Link to="/login">Cliquez ici pour vous connecter</Link>
        </div>
    );
};

export default VerificationSuccess;
