import React, { useState, useEffect } from 'react';
import { Card, ListGroup, Button, Row, Col, Container, Modal, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './MyContents.css'; // Import the CSS file

const MyContents = () => {
  const [userPosts, setUserPosts] = useState([]);
  const [error, setError] = useState(null);
  const [showRadiusModal, setShowRadiusModal] = useState(false);
  const [showBaliseModal, setShowBaliseModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [newRadius, setNewRadius] = useState('');
  const [balises, setBalises] = useState([]);
  const [selectedBaliseId, setSelectedBaliseId] = useState('');
  const [updatedPost, setUpdatedPost] = useState({ title: '', body: '', imageUrl: '', keywords: '', searchRadius: '' });
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserPosts();
    fetchBalises();
  }, []);

  const fetchUserPosts = async () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('userToken');

    if (userId && token) {
      try {
        const postsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/contents/user/${userId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!postsResponse.ok) {
          throw new Error(`HTTP status code: ${postsResponse.status}`);
        }

        const postsData = await postsResponse.json();
        setUserPosts(postsData);
      } catch (error) {
        console.error("Failed to fetch user posts:", error);
        setError(error.message);
      }
    } else {
      setError("User ID or token is missing.");
    }
  };

  const fetchBalises = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem('userToken');
    if (userId && token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/balises/user/${userId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP status code: ${response.status}`);
        }
        const balisesData = await response.json();
        setBalises(balisesData);
      } catch (error) {
        console.error("Erreur lors de la récupération des balises :", error);
      }
    }
  };

  const handleUpdateRadius = async () => {
    const apiURL = `${process.env.REACT_APP_API_BASE_URL}/api/contents/${currentPost.id}/update-radius`;

    try {
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        },
        body: JSON.stringify({ searchRadius: newRadius })
      });
      if (response.ok) {
        alert('Rayon de recherche mis à jour avec succès.');
        fetchUserPosts(); // Refresh posts after update
        setShowRadiusModal(false);
      } else {
        throw new Error('Failed to update radius.');
      }
    } catch (error) {
      console.error("Error updating radius:", error);
      alert("Erreur lors de la mise à jour du rayon de recherche.");
    }
  };

  const handleChangeBalise = async () => {
    const apiURL = `${process.env.REACT_APP_API_BASE_URL}/api/contents/${currentPost.id}/update-balise`;

    try {
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        },
        body: JSON.stringify({ baliseId: selectedBaliseId })
      });
      if (response.ok) {
        alert('Balise mise à jour avec succès.');
        fetchUserPosts(); // Refresh posts after update
        setShowBaliseModal(false);
      } else {
        throw new Error('Failed to update balise.');
      }
    } catch (error) {
      console.error("Error updating balise:", error);
      alert("Erreur lors de la mise à jour de la balise.");
    }
  };

  const handleDeletePost = async (postId) => {
    const apiURL = `${process.env.REACT_APP_API_BASE_URL}/api/contents/${postId}`;

    try {
      const response = await fetch(apiURL, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      if (response.ok) {
        alert('Contenu supprimé avec succès.');
        fetchUserPosts(); // Refresh posts after delete
      } else {
        throw new Error('Failed to delete post.');
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      alert("Erreur lors de la suppression du contenu.");
    }
  };

  const handleUpdatePost = async () => {
    const apiURL = `${process.env.REACT_APP_API_BASE_URL}/api/contents/${currentPost.id}`;

    try {
      const response = await fetch(apiURL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        },
        body: JSON.stringify({ 
          ...updatedPost, 
          keywords: updatedPost.keywords.split(',').map(kw => kw.trim()), 
          searchRadius: parseFloat(updatedPost.searchRadius) 
        })
      });
      if (response.ok) {
        alert('Contenu mis à jour avec succès.');
        fetchUserPosts(); // Refresh posts after update
        setShowUpdateModal(false);
      } else {
        throw new Error('Failed to update post.');
      }
    } catch (error) {
      console.error("Error updating post:", error);
      alert("Erreur lors de la mise à jour du contenu.");
    }
  };

  return (
    <Container className="mt-3">
      {error && <p className="text-danger">{error}</p>}
      <h3 className="mb-4">Mes publications</h3>
      <Row>
        {userPosts.length > 0 ? (
          userPosts.map(post => (
            <Col md={4} key={post.id} className="mb-4">
              <Card className="content-card">
                <Link to={`/content/${post.id}`} className="card-link">
                  <Card.Img className="content-image" variant="top" src={post.imageUrl} alt={post.title} />
                  <Card.Body>
                    <Card.Title>{post.title}</Card.Title>
                    <Card.Text>{post.body}</Card.Text>
                    <Card.Text className="text-muted">
                      Rayon de recherche : {post.searchRadius} km<br />
                      Balise : {post.balise.name}
                    </Card.Text>
                  </Card.Body>
                </Link>
                <Button variant="outline-primary" className="m-2" onClick={() => { setCurrentPost(post); setShowRadiusModal(true); }}>
                  Modifier le rayon de recherche
                </Button>
                <Button variant="outline-secondary" className="m-2" onClick={() => { setCurrentPost(post); setShowBaliseModal(true); }}>
                  Changer la balise
                </Button>
                <Button variant="outline-info" className="m-2" onClick={() => { 
                  setCurrentPost(post); 
                  setUpdatedPost({ 
                    title: post.title, 
                    body: post.body, 
                    imageUrl: post.imageUrl, 
                    keywords: post.keywords.join(', '),
                    searchRadius: post.searchRadius 
                  }); 
                  setShowUpdateModal(true); 
                }}>
                  Modifier le contenu
                </Button>
                <Button variant="outline-danger" className="m-2" onClick={() => handleDeletePost(post.id)}>
                  Supprimer
                </Button>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <Card>
              <Card.Body>
                <Card.Text>Aucun contenu trouvé.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>

      {/* Modal for updating search radius */}
      <Modal show={showRadiusModal} onHide={() => setShowRadiusModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier le rayon de recherche</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formSearchRadius">
              <Form.Label>Rayon de recherche (en km)</Form.Label>
              <Form.Control
                type="number"
                value={newRadius}
                onChange={(e) => setNewRadius(e.target.value)}
                placeholder="Entrez le nouveau rayon de recherche"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRadiusModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleUpdateRadius}>
            Mettre à jour
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for changing balise */}
      <Modal show={showBaliseModal} onHide={() => setShowBaliseModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Changer la balise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBalise">
              <Form.Label>Balise</Form.Label>
              <Form.Control
                as="select"
                value={selectedBaliseId}
                onChange={(e) => setSelectedBaliseId(e.target.value)}
              >
                <option value="">Sélectionnez une balise</option>
                {balises.map((balise) => (
                  <option key={balise.id} value={balise.id}>
                    {balise.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBaliseModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleChangeBalise}>
            Mettre à jour
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for updating post */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier le contenu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                value={updatedPost.title}
                onChange={(e) => setUpdatedPost({ ...updatedPost, title: e.target.value })}
                placeholder="Entrez le nouveau titre"
              />
            </Form.Group>
            <Form.Group controlId="formBody">
              <Form.Label>Contenu</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={updatedPost.body}
                onChange={(e) => setUpdatedPost({ ...updatedPost, body: e.target.value })}
                placeholder="Entrez le nouveau contenu"
              />
            </Form.Group>
            <Form.Group controlId="formImageUrl">
              <Form.Label>URL de l'image</Form.Label>
              <Form.Control
                type="text"
                value={updatedPost.imageUrl}
                onChange={(e) => setUpdatedPost({ ...updatedPost, imageUrl: e.target.value })}
                placeholder="Entrez la nouvelle URL de l'image"
              />
            </Form.Group>
            <Form.Group controlId="formKeywords">
              <Form.Label>Mots-clés</Form.Label>
              <Form.Control
                type="text"
                value={updatedPost.keywords}
                onChange={(e) => setUpdatedPost({ ...updatedPost, keywords: e.target.value })}
                placeholder="Entrez les nouveaux mots-clés, séparés par des virgules"
              />
            </Form.Group>
            <Form.Group controlId="formSearchRadiusUpdate">
              <Form.Label>Rayon de recherche (en km)</Form.Label>
              <Form.Control
                type="number"
                value={updatedPost.searchRadius}
                onChange={(e) => setUpdatedPost({ ...updatedPost, searchRadius: e.target.value })}
                placeholder="Entrez le nouveau rayon de recherche"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleUpdatePost}>
            Mettre à jour
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MyContents;
