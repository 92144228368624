import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import LoginPage from "./components/LoginPage";
import ProfilePage from "./components/ProfilePage";
import Header from "./components/Header";
import SearchPage from "./components/SearchPage"; // Ensure this import is correct
import ContentDetailPage from "./components/ContentDetailPage";
import AddBaliseForm from "./components/AddBaliseForm"; // Import the component
import AddContentForm from "./components/AddContentForm"; // Import the AddContentForm component
import Signup from "./components/Signup";
import UserList from "./components/UserList";
import AdminUserList from "./components/AdminUserList"; // Component for admin to see the list of users
import UserProfile from "./components/UserProfile"; //
import VerificationSuccess from "./components/VerificationSuccess";
import VerificationFailed from "./components/VerificationFailed";
import AddCategory from './components/AddCategory';
import AdminAddUser from "./components/AdminAddUser";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
   return (
      <>
         <Header />
         <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/search" element={<SearchPage />} /> {/* Ensure this route is defined */}
            <Route path="/content/:contentId" element={<ContentDetailPage />} />
            <Route path="/add-balise" element={<AddBaliseForm />} />
            <Route path="/add-content" element={<AddContentForm />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/admin/users" element={<AdminUserList />} /> {/* Admin user list */}
            <Route path="/admin/user/:id" element={<UserProfile />} />{" "}
            <Route path="/admin/add-category" element={<AddCategory />} />
            <Route path="/admin/add-user" element={<AdminAddUser />} />

            {/* Admin view of user profile */}
            <Route path="/verification-success" element={<VerificationSuccess />} />
            <Route path="/verification-failed" element={<VerificationFailed />} />
         </Routes>
      </>
   );
}

export default App;
