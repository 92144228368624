import React from 'react';
import './HeroSection.css'; // Import CSS file for custom styling

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-info">
        <h1>Bienvenue sur Foniprox</h1>
        <p>Un réseau pour le développement des relations sociales, économiques et culturelles grâce à la proximité</p>
        <p className="small-text">Parce que de près on se comprend mieux. Mais surtout, parce qu'on ne partage pas les mêmes choses.</p>
      </div>
    </div>
  );
};

export default HeroSection;
