import axios from "axios";
import { Alert, Button, Card, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";

const OPENCAGE_API_KEY = process.env.REACT_APP_OPENCAGE_API_KEY;

const AddBaliseForm = () => {
   const [formData, setFormData] = useState({
      type: "ORIGIN",
      latitude: "",
      longitude: "",
      name: "",
      address: "", // Address field for geocoding
      userId: "",
   });
   const [error, setError] = useState("");

   useEffect(() => {
      const userId = localStorage.getItem("userId");
      if (userId) {
         setFormData((prevFormData) => ({ ...prevFormData, userId }));
      }
   }, []);

   const handleInputChange = (name, value) => {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
   };

   const handleGeocodeAddress = async () => {
      if (!formData.address) {
         setError("Please enter an address.");
         return;
      }
      try {
         const response = await axios.get(`https://api.opencagedata.com/geocode/v1/json`, {
            params: {
               q: formData.address,
               key: OPENCAGE_API_KEY,
            },
         });
         if (response.data.results.length > 0) {
            const { lat, lng } = response.data.results[0].geometry;
            setFormData((prevFormData) => ({
               ...prevFormData,
               latitude: lat.toString(),
               longitude: lng.toString(),
            }));
            setError("");
         } else {
            setError("Failed to find the address.");
         }
      } catch (error) {
         console.error("Geocoding error:", error);
         setError("Failed to geocode address.");
      }
   };

   const handleSubmit = async () => {
      if (!formData.userId || !formData.name || !formData.latitude || !formData.longitude) {
         alert("Please fill in all required fields.");
         return;
      }
      try {
         const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/balises`, formData, {
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${localStorage.getItem("userToken")}`, // Assuming JWT is used
            },
         });
         if (response.status === 200) {
            alert("Balise added successfully");
            setFormData({
               type: "ORIGIN",
               latitude: "",
               longitude: "",
               name: "",
               address: "",
               userId: formData.userId,
            });
         } else {
            alert("Failed to add balise");
         }
      } catch (error) {
         console.error("Failed to add balise:", error);
         alert("Failed to add balise");
      }
   };

   return (
      <Card className="mt-3">
         <Card.Body>
            <Form onSubmit={(e) => e.preventDefault()}>
               <Form.Group controlId="formBaliseName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Enter balise name"
                     value={formData.name}
                     onChange={(e) => handleInputChange("name", e.target.value)}
                  />
               </Form.Group>
               <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Enter address to geocode"
                     value={formData.address}
                     onChange={(e) => handleInputChange("address", e.target.value)}
                  />
                  <Button onClick={handleGeocodeAddress} className="mt-2">
                     Convert Address
                  </Button>
               </Form.Group>
               <Form.Group controlId="formLatitude">
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Latitude"
                     value={formData.latitude}
                     readOnly
                  />
               </Form.Group>
               <Form.Group controlId="formLongitude">
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Longitude"
                     value={formData.longitude}
                     readOnly
                  />
               </Form.Group>
               {error && <Alert variant="danger">{error}</Alert>}
               <Button variant="primary" onClick={handleSubmit}>
                  Add Balise
               </Button>
            </Form>
         </Card.Body>
      </Card>
   );
};

export default AddBaliseForm;
