import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const RadiusSelectionModal = ({ show, handleClose, handleApply }) => {
  const [selectedRadius, setSelectedRadius] = useState(''); // Empty string allows free input

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Définir le rayon de recherche</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="radiusInput">
          <Form.Label>Entrez le rayon en kilomètres :</Form.Label>
          <Form.Control
            type="number"
            placeholder="Entrez le rayon (km)"
            value={selectedRadius}
            onChange={(e) => setSelectedRadius(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
        <Button variant="primary" onClick={() => handleApply(selectedRadius)}>
          Appliquer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RadiusSelectionModal;
