import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import './AdminUserList.css';

const UserCard = ({ user, onDelete, onEdit }) => {
  return (
    <div className="col-md-4 mb-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            {user.firstName} {user.lastName}
          </h5>
          <p className="card-text">{user.email}</p>
          <p className="card-text">{user.address}</p>
          <p className="card-text">
            Lat: {user.latitude}, Lng: {user.longitude}
          </p>
          <img src="../profile.jpg" className="card-img-top" alt="Profile Icon" />
          <Button variant="outline-info" className="m-2" onClick={() => onEdit(user)}>
            Edit
          </Button>
          <Button variant="outline-danger" className="m-2" onClick={() => onDelete(user.id)}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

const AdminUserList = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    latitude: "",
    longitude: "",
    password: ""
  });

  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users`;

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      console.log("Fetching users from API:", apiUrl); // Log the API URL
      const response = await axios.get(apiUrl);
      setUsers(response.data);
      console.log("Users fetched successfully:", response.data); // Log the fetched users
    } catch (error) {
      console.error("Error fetching users:", error);
      alert("Error fetching users."); // Show an alert for the error
    }
  };

  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?");
    if (confirmDelete) {
      try {
        console.log(`Deleting user with ID: ${userId}`); // Log the user ID
        await axios.delete(`${apiUrl}/${userId}`);
        console.log(`User with ID: ${userId} deleted successfully`);
        fetchUsers();
      } catch (error) {
        console.error("Error deleting user:", error);
        alert("Error deleting user."); // Show an alert for the error
      }
    }
  };

  const handleEditUser = (user) => {
    setCurrentUser(user);
    setUserData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      address: user.address,
      latitude: user.latitude,
      longitude: user.longitude,
      password: ""
    });
    setShowModal(true);
  };

  const handleUpdateUser = async () => {
    if (!currentUser || !userData.firstName || !userData.lastName || !userData.email) {
      alert("Please fill in all required fields");
      return;
    }

    console.log("Updating user with data:", userData); // Log the user data

    try {
      const response = await axios.put(`${apiUrl}/${currentUser.id}`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        password: userData.password,
        address: userData.address,
        latitude: userData.latitude,
        longitude: userData.longitude,
        role: "user",
        searchRadius: 10.0,
        enabled: true
      });

      console.log("Update response:", response); // Log the update response

      if (response.status === 200) {
        alert("User updated successfully!");
        fetchUsers();
        setShowModal(false);
      } else {
        alert(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert(`Error updating user: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <input
            type="text"
            placeholder="Rechercher des utilisateurs"
            className="form-control mb-3"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        {filteredUsers.map((user) => (
          <UserCard key={user.id} user={user} onDelete={handleDeleteUser} onEdit={handleEditUser} />
        ))}
      </div>

      {/* Modal for updating user */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={userData.firstName}
                onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={userData.lastName}
                onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                value={userData.address}
                onChange={(e) => setUserData({ ...userData, address: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formLatitude">
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                type="text"
                value={userData.latitude}
                onChange={(e) => setUserData({ ...userData, latitude: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formLongitude">
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                type="text"
                value={userData.longitude}
                onChange={(e) => setUserData({ ...userData, longitude: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={userData.password}
                onChange={(e) => setUserData({ ...userData, password: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateUser}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminUserList;
