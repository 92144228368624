import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Assurez-vous que le chemin est correct
import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();  // Obtenez la fonction login du contexte
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      console.log("Soumission du formulaire de connexion avec les données :", formData); // Journal des données du formulaire
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      // Gérer la réponse vide
      const data = response.status !== 204 ? await response.json() : {};

      console.log("Réponse reçue :", data); // Journal des données de réponse

      if (response.ok && data.token) {
        const firstName = data.firstName || 'Utilisateur'; // Fournir une valeur par défaut si firstName est manquant
        localStorage.setItem('userToken', data.token);
        localStorage.setItem('userId', data.id);
        localStorage.setItem('userFirstName', firstName); // Stocker firstName dans localStorage
        localStorage.setItem('userRole', data.role); // Stocker role dans localStorage
        console.log("Stocké dans localStorage :", {
          userToken: data.token,
          userId: data.id,
          userFirstName: firstName,
          userRole: data.role
        }); // Journal des données stockées
        login({ ...data, firstName, token: data.token }); // Passer les données complètes de l'utilisateur à la fonction login

        if (data.role === 'user') {
          navigate('/');
        } else if (data.role === 'editor') {
          navigate('/dashboard');
        } else if (data.role === 'admin') {
          navigate('/admin/users');
        } else {
          alert('Accès refusé. Rôle non autorisé.');
        }
      } else {
        alert('Échec de la connexion. Veuillez vérifier vos identifiants.');
      }
    } catch (error) {
      console.error('Erreur de connexion :', error);
      alert('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h1>Bienvenue</h1>
        <input
          className="login-input"
          onChange={e => handleChange('email', e.target.value)}
          value={formData.email}
          placeholder="Email"
        />
        <input
          className="login-input"
          type="password"
          onChange={e => handleChange('password', e.target.value)}
          value={formData.password}
          placeholder="Mot de passe"
        />
        <button onClick={handleSubmit} className="login-button">
          Connexion
        </button>
        <p>
          Vous n'avez pas de compte ?{' '}
          <span onClick={() => navigate('/signup')} className="signup-link">
            Inscrivez-vous
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
