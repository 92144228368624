import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faGoogle, faInstagram, faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div className="container">
      <footer className="text-center bg-body-tertiary">
        <div className="container pt-4">
          <section className="mb-4">
            {/* Facebook */}
            <a className="btn btn-link btn-floating btn-lg text-body m-1" href="#!" role="button">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            {/* Twitter */}
            <a className="btn btn-link btn-floating btn-lg text-body m-1" href="#!" role="button">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            {/* Google */}
            <a className="btn btn-link btn-floating btn-lg text-body m-1" href="#!" role="button">
              <FontAwesomeIcon icon={faGoogle} />
            </a>
            {/* Instagram */}
            <a className="btn btn-link btn-floating btn-lg text-body m-1" href="#!" role="button">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            {/* Linkedin */}
            <a className="btn btn-link btn-floating btn-lg text-body m-1" href="#!" role="button">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            {/* Github */}
            <a className="btn btn-link btn-floating btn-lg text-body m-1" href="#!" role="button">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </section>
        </div>

        <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          © 2024 Copyright:
          <a className="text-body" href="#">.fr</a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
